<template>
  <div class="annex">
    <!-- 下拉框productionFundBudgetType -->
    <el-descriptions direction="horizontal" :column="4" border>
      <el-descriptions-item label="项目预算类型：">
        <el-select
          style="margin-bottom: 10px"
          v-model="projectInformation.productionFundBudgetType"
          placeholder="请选择"
        >
          <el-option
            v-for="item in dictData.productionFundBudgetTypes"
            :key="item.value"
            :label="item.label"
            :value="item.value"
            :disabled="disabled"
          ></el-option>
        </el-select>
        <!-- 类型说明 按钮点击弹窗 -->
        <el-button
          style="margin-left: 10px"
          type="success"
          size="mini"
          @click="showTypeExplain"
        >
          类型说明
        </el-button>
      </el-descriptions-item>
      <el-descriptions-item label="内部生产预算资金：">
        <el-input
          style="margin-bottom: 10px"
          class="inp"
          placeholder="请输入内部生产预算资金"
          v-model="projectInformation.estimatedProductionCost"
          @input="inputAmount"
          @focus="focusAmount"
          @blur="blurAmount"
          >元
          <!-- <template slot="prepend">预估成本金额：</template> -->
        </el-input>
      </el-descriptions-item>
    </el-descriptions>

    <!-- <UploadList
      :disabled="disabled"
      :tableHeight="null"
      :fields="{ name: 'fileName', path: 'filePath', type: 'projectId' }"
      :value="projectCostPlanFileList"
      @change="change($event)"
      :type="projectInformation.id"
    >
    </UploadList>
    <div style="color: red">* 预估成本方案请上传PDF的格式,目前只有PDF的文件才能预览</div> -->
    <el-dialog
      title="预算类型说明"
      :visible.sync="typeExplainVisible"
      width="47%"
      append-to-body
    >
      <div slot="title">
        <span>项目预算类型：</span>
        <el-select
          v-model="productionFundBudgetType"
          placeholder="请选择"
        >
          <el-option
            v-for="item in dictData.productionFundBudgetTypes.slice(0, 2)"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          ></el-option>
        </el-select>
      </div>

      <div
        v-if="productionFundBudgetType === 'COST_QUOTA_CATEGORY'"
        class="type-explain"
      >
        <h3>一、成本定额类</h3>
        <p>
          在具体定额时应考虑对生产成本有重要影响的项目特性，比如难度系数、区域（专家费、检测费的不同）等
        </p>

        <el-tabs type="border-card">
          <el-tab-pane label="报批类业务">
            <h4>1. 成熟型业务定额标准 - 报批类业务</h4>
            <p>
              报批类项目实施费用主要包括项目实施成本（人员成本、交通或住宿成本、成果装订成本等）、审核成本、评审费用、非常规沟通费用。其中项目实施成本由项目组支配；审核成本含到部门管理费中支出，超出部分由审核人员承担，因成果质量导致的反复检查，成本由项目组承担；专家评审和土壤检测等费用从公司公共费用中实报实销，不列入实施费用，因项目组失误导致的重复评审费用则由项目组承担；非常规沟通指除项目组日常与相关方沟通外，需要协调相关领导的沟通，这部分从部门管理费中支出。
            </p>
            <div class="table-div">
              <el-image
                style="width: 700px; height: 400px"
                :src="require('@/assets/成熟型标准-报批类.png')"
              ></el-image>
            </div>

            <el-collapse>
              <el-collapse-item title="查看备注信息">
                <ol>
                  <li>
                    1、实施组按报批人员平均税前工资10900元为基准计算工作日工资，减去公司承担的基本薪酬2900元（基本工资2500元，本科100元,全勤300元），剩8000元；每月22工作日，10%绩效，按400元/工作日计。
                  </li>
                  <li>2、审核组按实施组的1.5倍计算，按600元/工作日计。</li>
                  <li>3、按部门管理费占实施费用25%的比例计算部门管理费。</li>
                </ol>
              </el-collapse-item>
            </el-collapse>
          </el-tab-pane>

          <el-tab-pane label="林业类业务">
            <h4>2. 成熟型业务定额标准 - 林业类业务</h4>
            <p>林业类定额项目类型包括树木保护专章和林地报批类业务。</p>
            <p>
              项目实施费用主要包括项目实施成本（人员成本、交通或住宿成本、成果装订成本等）、审核成本、评审费用、非常规沟通费用。其中项目实施成本由项目组支配；审核成本含到部门管理费中支出，超出部分由审核人员承担，因成果质量导致的反复检查，成本由项目组承担；专家评审费用从公司公共费用中实报实销，不列入实施费用，因项目组失误导致的重复评审费用则由项目组承担；非常规沟通指除项目组日常与相关方沟通外，需要协调相关领导的沟通，这部分从部门管理费中支出。
            </p>

            <!-- <el-table
              :data="costQuotaTable2"
              border
              style="width: 100%; margin-bottom: 20px"
              size="small"
            >
              <el-table-column
                prop="seq"
                label="序号"
                width="70"
              ></el-table-column>
              <el-table-column
                prop="projectType"
                label="项目类型"
              ></el-table-column>
              <el-table-column
                prop="personnelCost"
                label="人员成本"
              ></el-table-column>
              <el-table-column
                prop="bindingCost"
                label="成果装订成本"
              ></el-table-column>
              <el-table-column
                prop="subsidy"
                label="补贴(含交通住宿)"
              ></el-table-column>
              <el-table-column
                prop="auditCost"
                label="审核成本"
              ></el-table-column>
              <el-table-column
                prop="implementCost"
                label="实施费用"
              ></el-table-column>
              <el-table-column
                prop="deptManageCost"
                label="部门管理费"
              ></el-table-column>
              <el-table-column
                prop="totalCost"
                label="项目实施费用"
              ></el-table-column>
            </el-table> -->

            <div class="table-div">
              <el-image
                style="width: 580px; height: 250px"
                :src="require('@/assets/成熟型标准-林业类.png')"
              ></el-image>
            </div>
            <el-collapse>
              <el-collapse-item title="查看备注信息">
                <ol>
                  <li>
                    1、鸿森技术员按平均税前工资7500元为基准计算工作日工资，减去公司承担的基本薪酬2800元（基本工资2500元，全勤300元），剩4700元；每月22工作日，10%绩效，按240元/工作日计。
                  </li>
                  <li>2、审核组按实施组的1.5倍计算，按360元/工作日计。</li>
                  <li>3、按部门管理费占实施费用25%的比例计算部门管理费。</li>
                  <li>
                    4、树木保护专章定额标准按线状10公里±20%，块状1公顷（200棵）±20%的规模评估得出；超出范围或特殊项目由部门经理根据实际情况参考定额标准评估后报公司确定。
                  </li>
                  <li>
                    5、林地报批定额标准按报批到区县级，其他级别系数：市级1.1，省级1.2。
                  </li>
                </ol>
              </el-collapse-item>
            </el-collapse>
          </el-tab-pane>
        </el-tabs>
      </div>
      <div
        v-else-if="productionFundBudgetType === 'DISPOSABLE_CONTRACT_CATEGORY'"
        class="type-explain"
      >
        <h3>二、可支配合同额比例类</h3>
        <el-table
          :data="contractRatioTable"
          border
          style="width: 100%; margin-bottom: 20px"
          :span-method="contractRatioSpanMethod"
        >
          <el-table-column prop="seq" label="序号" width="70"></el-table-column>
          <el-table-column prop="type" label="类型"></el-table-column>
          <el-table-column label="项目性质类型" align="center">
            <el-table-column
              prop="directSign"
              label="一手直签"
              width="120"
            ></el-table-column>
            <el-table-column
              prop="qualification"
              label="资质合作"
              width="180"
            ></el-table-column>
            <el-table-column
              prop="subcontract"
              label="分包转包"
              width="120"
            ></el-table-column>
          </el-table-column>
        </el-table>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import {
  getInputValue,
  delcommafy,
  numberToCurrencyNo,
} from "@/util/jsencrypt";
import { mapGetters, mapState } from "vuex";

export default {
  components: {
    UploadList: () => import("@/components/upload/List.vue"),
  },
  props: {
    options: {
      type: Object,
      default: function () {
        return {};
      },
    },

    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      fileData: [],
      productionFundBudgetType: "COST_QUOTA_CATEGORY",
      typeExplainVisible: false,
      dictData: {
        productionFundBudgetTypes: [
          {
            value: "COST_QUOTA_CATEGORY",
            label: "成本定额类",
          },
          {
            value: "DISPOSABLE_CONTRACT_CATEGORY",
            label: "可支配合同额比例类",
          },
          {
            value: "PRODUCTION_FUND_APPROVAL_CATEGORY",
            label: "生产资金单独批准类",
          },
        ],
      },
      // 成本定额类 - 报批类业务表格数据
      costQuotaTable1: [
        {
          seq: 1,
          projectType: "临时用地",
          personnelCost: "规模选址独立（44工作日）18040",
          bindingCost: "400",
          subsidy: "2000",
          auditCost: "3工作日",
          implementCost: "2460",
          deptManageCost: "22900",
          totalCost: "7633/30533",
        },
        {
          seq: 2,
          projectType: "",
          personnelCost: "规模选址融合（37工作日）15170",
          bindingCost: "200",
          subsidy: "",
          auditCost: "",
          implementCost: "19830",
          deptManageCost: "6610",
          totalCost: "26440",
        },
        {
          seq: 3,
          projectType: "耕作层剥离",
          personnelCost: "剥离（15工作日）6150",
          bindingCost: "200",
          subsidy: "800",
          auditCost: "1工作日",
          implementCost: "615",
          deptManageCost: "7765",
          totalCost: "2588/10353",
        },
        {
          seq: 4,
          projectType: "",
          personnelCost: "不剥离（10工作日）4100",
          bindingCost: "200",
          subsidy: "600",
          auditCost: "",
          implementCost: "5515",
          deptManageCost: "1838",
          totalCost: "7353",
        },
        {
          seq: 5,
          projectType: "复垦验收（20工作日）",
          personnelCost: "8200",
          bindingCost: "200",
          subsidy: "2000",
          auditCost: "1工作日",
          implementCost: "615",
          deptManageCost: "11015",
          totalCost: "2753/13768",
        },
        {
          seq: 6,
          projectType: "社稳评估（15工作日）",
          personnelCost: "6150",
          bindingCost: "200",
          subsidy: "1200",
          auditCost: "1工作日",
          implementCost: "615",
          deptManageCost: "8165",
          totalCost: "2722/10887",
        },
      ],
      // 成本定额类 - 林业类业务表格数据
      costQuotaTable2: [
        {
          seq: 1,
          projectType: "树木保护专章",
          personnelCost: "线状项目（117工作日）29250",
          bindingCost: "200",
          subsidy: "6300",
          auditCost: "5工作日",
          implementCost: "1875",
          deptManageCost: "37625",
          totalCost: "12542/50167",
        },
        {
          seq: 2,
          projectType: "",
          personnelCost: "块状项目（51工作日）12750",
          bindingCost: "200",
          subsidy: "1850",
          auditCost: "3工作日",
          implementCost: "1125",
          deptManageCost: "15925",
          totalCost: "5308/21233",
        },
        {
          seq: 3,
          projectType: "林地报批（46工作日）",
          personnelCost: "11500",
          bindingCost: "200",
          subsidy: "1600",
          auditCost: "5工作日",
          implementCost: "1875",
          deptManageCost: "15175",
          totalCost: "5058/20233",
        },
      ],
      // 可支配合同额比例类表格数据
      contractRatioTable: [
        {
          seq: 1,
          type: "测绘调查类",
          directSign: "35%",
          qualification: "35%/(1-合作费比例）",
          subcontract: "60%",
        },
        {
          seq: 2,
          type: "数据建库类",
          directSign: "30%",
          qualification: "30%/(1-合作费比例）",
          subcontract: "55%",
        },
        {
          seq: 3,
          type: "规划设计类",
          directSign: "35%",
          qualification: "35%/(1-合作费比例）",
          subcontract: "60%",
        },
        {
          seq: 4,
          type: "报批咨询类",
          directSign: "25%",
          qualification: "25%/(1-合作费比例）",
          subcontract: "50%",
        },
        {
          seq: 5,
          type: "软件开发类",
          directSign: "50%",
          qualification: "50%/(1-合作费比例）",
          subcontract: "70%",
        },
        {
          seq: 6,
          type: "驻场监理类",
          directSign: "30%",
          qualification: "30%/(1-合作费比例）",
          subcontract: "55%",
        },
        {
          seq: 7,
          type: "软件销售类",
          directSign: "按照项目实际情况确定",
          qualification: "",
          subcontract: "",
        },
        {
          seq: 8,
          type: "档案类",
          directSign: "按照项目实际情况确定",
          qualification: "",
          subcontract: "",
        },
        {
          seq: 9,
          type: "工程施工类",
          directSign: "按照项目实际情况确定",
          qualification: "",
          subcontract: "",
        },
      ],
    };
  },
  computed: {
    ...mapState({
      projectInformation: (state) => state.project.projectInformation,
      projectCostPlanFileList: (state) => state.project.projectCostPlanFileList,
    }),
  },
  methods: {
    showTypeExplain() {
      this.typeExplainVisible = true;
    },
    /** 值改变触发 "申请金额" 文本框 **/
    inputAmount() {
      this.projectInformation.projectBudget = String(
        this.projectInformation.projectBudget
      )
        .replace(/[^\d.]/g, "")
        .replace(/^(\-)*(\d+)\.(\d\d).*$/, "$1$2.$3");
      this.$forceUpdate();
    },
    /** 获得焦点触发 "申请金额" 文本框 **/
    focusAmount() {
      if (
        this.projectInformation.projectBudget == null ||
        this.projectInformation.projectBudget == "" ||
        this.projectInformation.projectBudget == undefined
      ) {
        this.projectInformation.projectBudget = 0;
      } else {
        this.projectInformation.projectBudget = String(
          this.projectInformation.projectBudget
        ).replace(/,/g, "");
      }
      this.$forceUpdate();
    },

    /** 失去焦点触发 "申请金额" 文本框 **/
    blurAmount() {
      this.projectInformation.projectBudget = Number(
        this.projectInformation.projectBudget
      ).toLocaleString();
      this.$forceUpdate();
    },

    change(r) {
      this.$store.commit({
        type: "project/SET_PROJECTCOSTPLANFILELIST",
        projectCostPlanFileList: r,
      });
    },

    // 可支配合同额比例表格的合并方法
    contractRatioSpanMethod({ row, column, rowIndex, columnIndex }) {
      // 序号7、8、9（rowIndex为6、7、8）的行需要合并单元格
      if (rowIndex >= 6 && rowIndex <= 8) {
        // directSign列（列索引实际上是2，因为前面有序号和类型两列）
        if (columnIndex === 2) {
          return {
            rowspan: 1,
            colspan: 3, // 合并三列：一手直签、资质合作、分包转包
          };
        }
        // qualification列和subcontract列（列索引3和4）
        else if (columnIndex === 3 || columnIndex === 4) {
          return {
            rowspan: 0,
            colspan: 0,
          };
        }
      }
    },

    // 第一个表格的合并方法
    arraySpanMethod1({ row, column, rowIndex, columnIndex }) {
      // 项目类型列
      if (columnIndex === 1) {
        // 临时用地
        if (rowIndex === 0) {
          return {
            rowspan: 2,
            colspan: 1,
          };
        } else if (rowIndex === 1) {
          return {
            rowspan: 0,
            colspan: 0,
          };
        }
        // 耕作层剥离
        else if (rowIndex === 2) {
          return {
            rowspan: 2,
            colspan: 1,
          };
        } else if (rowIndex === 3) {
          return {
            rowspan: 0,
            colspan: 0,
          };
        }
      }

      // 合并补贴列(原本的补贴列应该是columnIndex为4)
      if (columnIndex === 4) {
        if (rowIndex === 0) {
          return {
            rowspan: 2,
            colspan: 1,
          };
        } else if (rowIndex === 1) {
          return {
            rowspan: 0,
            colspan: 0,
          };
        }
      }

      // 合并审核成本列(原本的审核成本列应该是columnIndex为5)
      if (columnIndex === 5) {
        if (rowIndex === 0) {
          return {
            rowspan: 2,
            colspan: 1,
          };
        } else if (rowIndex === 1) {
          return {
            rowspan: 0,
            colspan: 0,
          };
        } else if (rowIndex === 2) {
          return {
            rowspan: 2,
            colspan: 1,
          };
        } else if (rowIndex === 3) {
          return {
            rowspan: 0,
            colspan: 0,
          };
        }
      }
    },
  },
};
</script>
<style scoped lang="scss">
@import "@/styles/config.scss";
p {
  text-indent: 2em;
}
/deep/.el-dialog__body {
  height: 65vh;
  overflow: auto;
}
.annex {
  .inp {
    width: 400px;
  }
}
.operation {
  position: sticky;
  bottom: 0;
  padding: 10px;
  text-align: center;
  .el-button {
    width: 121px;
    margin: 0 15px;
  }
}
.type-explain {
  h3 {
    margin-bottom: 15px;
    color: #303133;
  }
  h4 {
    margin-top: 20px;
    margin-bottom: 10px;
    color: #606266;
  }
  p {
    margin-bottom: 10px;
    line-height: 1.6;
  }
  ol {
    padding-left: 20px;
    li {
      margin-bottom: 5px;
    }
  }

  /deep/ .el-tabs__header {
    margin-bottom: 15px;
  }

  /deep/ .el-collapse-item__header {
    color: #409eff;
    font-size: 14px;
  }

  /deep/ .el-table th {
    background-color: #f5f7fa;
  }
}
.table-div {
  width: 100%;
}
</style>
